<template>
  <VTable
    :head-row="headRow"
    :touch-bar-list="touchBarDelete"
    url-set-rows="/registers/auth-history"
    url-action-query="expand=status"
    url-action="/users/action"
    @link="goToAdminUser"
  />
</template>

<script setup>
import VTable from '@/components/ui/table/VTable'
import { defineComponent } from 'vue'

import { touchBarDelete } from '@/utils/touchbar'
import { goToAdminUser } from '@/utils/goRoute'

// Lets
const headRow = [
  {
    name: 'Логин',
    value: 'login',
    filter: true,
    decorator: {
      name: 'link'
    }
  },
  {
    name: 'Дата/время входа',
    value: 'action_at',
    filter: true
  },
  {
    name: 'IP',
    value: 'ip',
    filter: true
  },
  {
    name: 'Признак успешности',
    value: 'status',
    filter: true,
    decorator: {
      name: 'color'
    }
  }
]

defineComponent({
  VTable
})
</script>
